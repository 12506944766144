<template>
  <div class="mb-3">
    <CRow class="my-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-end">
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
        </CCol>
    </CRow>

    <CRow class="mt-2">
        <CCol sm="12">
            <dataTableExtended
                class="align-center-row-datatable"
                :items="computedEventContainer"
                :fields="Eventfields"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                :loading="loading"
                sorter
            >
                <template #Status="{item}">
                    <td class="text-center">
                    <CBadge :color="getBadge(item.Status)">{{item.Status}}</CBadge>
                    </td>
                </template>
            
                <template #options="{ item }">
                    <td class="text-center">
                        <CButton
                        square
                        size="sm"
                        color="watch"
                        class="d-flex align-items-center"
                        @click="toggleContainerEdit(item)"
                        v-c-tooltip="{
                            content: $t('label.edit')+' '+$t('label.eirTransaction'),
                            placement: 'top-end'
                        }"
                        >
                        <CIcon name="eye" />
                        </CButton>
                    
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
    </CRow>
  </div>
</template>
<script>
    import General from '@/_mixins/general';
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import ReportSearchContainer from '@/_mixins/reportsSearchContainer';
    import ENUM from '@/_store/enum';

    //data
    function data() {
        return {
            Items: [],
            loading: false,
            ModalBlContainer: false,
            ContainerItem:{},
            dataEvent: [],
        };
    }

    async function onBtnPdf(){
    }

    async function onBtnExport(valor) {
        let _lang = this.$i18n.locale;
        this.$store.state.containerSearch.loading = ENUM.LOADING;
        let tableHeader = {label:[], key:[]};
        let rowDataFormated = [];

        this.Eventfields.map(item=>{   
            tableHeader.label.push(item.label);
            tableHeader.key.push(item.key);
        })

        for (let i = 0; i < this.dataEvent.length; i++) {

            let YardNewDetailPosJson = this.dataEvent[i]?.YardNewDetailPosJson?.filter((element) => { return element.YardSlotStatus.toUpperCase() === process.env.VUE_APP_CONTAINER_SEARCH_NEW_POSITION_STATUS_ID; }).map(element=> element.SlotCode.trim());
            
            let PreviousDetailPosJson = this.dataEvent[i]?.YardPreviousDetailPosJson?.filter((element) => { return element.YardSlotStatus.toUpperCase() === process.env.VUE_APP_CONTAINER_SEARCH_NEW_POSITION_STATUS_ID; }).map(element=> element.SlotCode.trim());

            rowDataFormated.push({

                RowNumber:  this.dataEvent[i].IdX,
                EventName: (_lang=='en' ?  this.dataEvent[i].EventNameEn :  this.dataEvent[i].EventNameEs) ?? 'N/A',
                PreviousYardSlotCode: PreviousDetailPosJson ? PreviousDetailPosJson?.join(', ') : 'N/A',
                YardSlotCode: YardNewDetailPosJson ? YardNewDetailPosJson?.join(', ') : 'N/A',
                DeviceCod:  this.dataEvent[i].DeviceCod ?? 'N/A',
                TransactionDate:  this.dataEvent[i].TransactionDate ? DateFormater.formatDateTimeWithSlash( this.dataEvent[i].TransactionDate) : 'N/A',
                TransaLogin:  this.dataEvent[i].TransaLogin ?? 'N/A',
                Status:  this.dataEvent[i].Status ?? 'N/A',
            });   
        }

        if(this.nulo!="null" && this.dataEvent.length !== 0) {
            await this.generateExcel(rowDataFormated, this.$t('label.events'), valor, tableHeader.label, false, tableHeader, 'SERVICIOS LOGISTICOS OCK', this.$store.state.containerSearch.dataContainer);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.containerSearch.loading = ENUM.LOADED;
    }

    function computedEventContainer() {

        let _lang = this.$i18n.locale;
        let data = [];

        
        
        this.dataEvent?.map(
          (item) => {
            let YardNewDetailPosJson = item?.YardNewDetailPosJson?.filter((element) => { return element.YardSlotStatus.toUpperCase() === process.env.VUE_APP_CONTAINER_SEARCH_NEW_POSITION_STATUS_ID; }).map(element=> element.SlotCode.trim());
            
            let PreviousDetailPosJson = item?.YardPreviousDetailPosJson?.filter((element) => { return element.YardSlotStatus.toUpperCase() === process.env.VUE_APP_CONTAINER_SEARCH_NEW_POSITION_STATUS_ID; }).map(element=> element.SlotCode.trim());
+

            data.push(
                {
                    RowNumber: item.IdX,
                    EventName: (_lang=='en' ? item.EventNameEn : item.EventNameEs) ?? 'N/A',
                    PreviousYardSlotCode: PreviousDetailPosJson ? PreviousDetailPosJson?.join(', ') : 'N/A',
                    YardSlotCode: YardNewDetailPosJson ? YardNewDetailPosJson?.join(', ') : 'N/A',
                    DeviceCod: item.DeviceCod ?? 'N/A',
                    TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : 'N/A',
                    TransactionBegin: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : 'N/A',
                    TransactionFinish: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : 'N/A',
                    TransaLogin: item.TransaLogin ?? 'N/A',
                    Status: item.Status ?? 'N/A',
                    _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
                }
            )
          }
        );

        return  data
    }

    function Eventfields(){
        return [
            { key: 'RowNumber', label: '#', _style: 'width:3%; text-align:center', _classes: 'text-center', filter: false },
            { label: this.$t('label.events'), key: 'EventName', _classes: 'text-uppercase text-center', _style: 'width:11%; text-align:center;', sorter: true, filter: true},
            { key: 'PreviousYardSlotCode', label: this.$t('label.previousPosition'), _style:'width:16%; text-align:center;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
            { key: 'YardSlotCode', label: this.$t('label.newPosition'), _style:'width:18%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
            { key: 'DeviceCod', label: this.$t('label.device'),  _style:'width:15%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'TransactionDate', label: this.$t('label.date'),  _style:'width:12%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'TransactionBegin', label: this.$t('label.initialDate'),  _style:'width:12%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'TransactionFinish', label: this.$t('label.finalDate'),  _style:'width:12%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'TransaLogin', label: this.$t('label.user'),  _style:'width:12%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'Status', label: this.$t('label.status'),  _style:'width:9%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
        ];
    }
    

    export default{
        name: 'events-list',
        data,
        mixins: [General, ReportSearchContainer],
        components: {
        },
        methods: {
            onBtnExport,
            onBtnPdf
        },
        computed: {
            Eventfields,
            computedEventContainer,
            ...mapState({
                dataContainer: state => state.containerSearch.dataContainer,
            })
        },
        watch:{
            dataContainer: function (newValue) {
                if (Object.keys(newValue).length > 0 && this.$store.state.containerSearch.dataContainer?.EventsJson){
                    this.dataEvent = this.$store.state.containerSearch.dataContainer?.EventsJson;
                }
                else
                    this.dataEvent = [];
            },
        }
    }
</script>
<style lang="scss">

    .icon{
        background-color: null; 
        border: 0px;
    }
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
</style>